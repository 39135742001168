<template>
  <div class="d-flex justify-end align-center col-12">
    <v-menu offset-y nudge-bottom="22" min-width="55" left :elevation="$vuetify.theme.dark ? 9 : 8">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" class="d-flex align-center" v-on="on">
          <v-img
            :src="$i18n.locale === 'en' ? locales[0].img : locales[1].img"
            :alt="$i18n.locale"
            height="17px"
            width="27px"
            class="mr-md-1"
          ></v-img>
          <span class="ml-1 text-sm d-none d-md-inline-block">{{
            $i18n.locale === 'en' ? locales[0].title : locales[1].title
          }}</span>
        </div>
      </template>
      <!-- Options List -->
      <v-list>
        <v-list-item-group :value="$i18n.locale">
          <v-list-item
            v-for="locale in locales"
            :key="locale.locale"
            :value="currentLang"
            @change="changeLanguage(locale.locale)"
          >
            <v-img :src="locale.img" height="14px" width="22px" :alt="locale.locale" class="me-2"></v-img>
            <v-list-item-title>{{ locale.title }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <!-- <v-list>
      <v-list-item
        @click="changeLanguage(item)"
        v-for="(item, index) in languageOptions"
        :key="index"
      >
        <v-list-item-title>{{ item.toUpperCase() }}</v-list-item-title>
      </v-list-item>
    </v-list> -->
    </v-menu>
  </div>
</template>

<script>
import { ref, getCurrentInstance } from '@vue/composition-api';

const languageOptions = ['en', 'de'];
export default {
  setup() {
    const vm = getCurrentInstance().proxy;
    const currentLang = ref('de');

    const changeLanguage = (newValue) => {
      vm.$i18n.locale = newValue === 'en' ? 'en' : 'de_form';
      vm.$store.commit('app/setCurrentLang', newValue);
      currentLang.value = newValue;
      document.title = vm.$t(vm.$route.meta.titleKey) || 'Degura';
    };
    const locales = [
      {
        title: 'English',
        img: require('@/assets/images/flags/en.png'),
        locale: 'en',
      },
      {
        title: 'Deutsch',
        img: require('@/assets/images/flags/de.svg'),
        locale: 'de',
      },
    ];
    return {
      currentLang,
      changeLanguage,
      languageOptions,
      locales,
    };
  },
};
</script>
<style lang="scss"></style>
